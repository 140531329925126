import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const CustomDatePicker = ({ label, onChange, ...props }) => {
  const [ value, setSelectedDate] = useState(dayjs(new Date()));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        renderInput={(params) => <TextField {...params} />}
        value={value}
        onChange={(newValue) => {
          setSelectedDate(dayjs(newValue));
          onChange(newValue ? newValue.toDate() : null);
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomDatePicker;