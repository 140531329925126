import { createApi } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';
import { logout } from '../Reducers/Auth/AuthSilice';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        passwordChange: builder.mutation({
            query: (req) => ({
                url: `user/change-password`,
                method: 'POST',
                body: req,
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    toast.success(data?.message ?? '');
                } catch (err) {
                    toast.error(err?.error?.data?.message ?? '');
                    if (err?.error?.status === 401) {
                        dispatch(logout());
                        return false;
                    }
                }
            },
        }),
        
        getUsers: builder.query({
            query: ({ limit = 10, page = 0, orderBy = 'DESC', search = '' }) => ({
                url: `/user?limit=${limit}&page=${page}&orderBy=${orderBy}&search=${search}`,
                method: 'GET',
            })
        }),

        createUser: builder.mutation({
            query: (req) => ({
                url: `user/create`,
                method: 'POST',
                body: req,
            }),
        }),

        updateUser: builder.mutation({
            query: (req) => ({
                url: `user/update`,
                method: 'POST',
                body: req,
            })
        }),

        updateStatus: builder.mutation({
            query: (id) => ({
                url: `user/${id}`,
                method: 'PUT'
            })
        }),

        getUserById: builder.query({
            query: (id) => ({
                url: `user/${id}`,
                method: 'GET',
            })
        }),
    }),
});

export const { usePasswordChangeMutation, useUpdateStatusMutation, useGetUsersQuery, useCreateUserMutation, useGetUserByIdQuery, useUpdateUserMutation } = userApi;