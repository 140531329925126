/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Dropmenu, Search } from "../../Components";
import { Link, useNavigate } from "react-router-dom";
import { useGetContractListQuery } from "../../Redux/Service/ContractApi";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { API_URL } from "../../Config/index";
import Loading from "../../Components/Loading";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  setBankDetails,
  setContractPrefilledData,
  setLeads,
  setQuotation,
  setTechnologyStacks,
} from "../../Redux/Reducers/Contracts/ContractSlice";
import { getAuthToken } from "../../Redux/Service/FetchBaseQuery";
import axios from "axios";
import { useDispatch } from "react-redux";
// import PostAddIcon from '@mui/icons-material/PostAdd';

function ContractList() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const [contractData, setContractData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [AccessToken, setAccessToken] = useState(null);

  const dispatch = useDispatch();
  React.useEffect(() => {
    setAccessToken(getAuthToken());
    axios
      .get(`${API_URL}contract-content/contents`, {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        dispatch(setContractPrefilledData(response.data?.data));
      });

    axios
      .get(`${API_URL}technology-stack/list-technology`, {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        dispatch(setTechnologyStacks(response.data?.response));
      });

    axios
      .get(`${API_URL}lead/list `, {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((response) => {
        dispatch(setLeads(response.data.response));
      });

    axios.get(`${API_URL}quotation/all`, {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
      },
    }).then((response) => {
      dispatch(setQuotation(response.data?.response?.data));
    })

    axios.get(`${API_URL}contract-content/bank-detail`, {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
      }
    }).then((response) => {
      dispatch(setBankDetails(response.data?.data?.response));
    })
  }, [AccessToken, dispatch]);

  const {
    data: contract,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetContractListQuery({
    limit: rowsPerPage,
    page: page,
    orderBy: "DESC",
    search: searchQuery,
  });

  const customStyles = {
    rows: {
      style: {
        margin: "0px",
        padding: 0,
      },
    },
    headCells: {
      style: {
        margin: "0px",
        padding: "10px 15px",
        fontWeight: 600,
        fontSize: 15,
        background: "#f3f3f3",
      },
    },
    cells: {
      style: {
        margin: "0px",
        padding: "10px 15px",
        fontSize: 15,
      },
    },
  };

  useEffect(() => {
    refetch();
  }, [refetch, searchQuery]);

  useEffect(() => {
    if (!isLoading && !isError) {
      // setContractData(contract?.response);
      setLoading(false);
    }
  }, [isLoading, isError, contract]);

  const handleEdit = (id) => {
    navigate(`/contract-edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/quotation/${id}`);
  };

  const handleSearch = (search) => {
    setSearchQuery(search);
    setPage(1);
  };

  const columns = [
    {
      name: "SL No",
      cell: (row, index) => index + 1,
      button: false,
    },
    {
      name: "Created By",
      selector: (row) => row.user && row.user?.name,
      sortable: true,
    },
    {
      name: "Lead Name",
      selector: (row) => row.lead && row?.lead?.name,
      sortable: true,
    },
    {
      name: "Quotation Id",
      selector: (row) => row.quotation && row.quotation?.uniqueId,
      sortable: true,
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.createdAt).toDateString(),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency.symbol ?? 0,
      sortable: true,
    },
    {
      name: "FILE",
      selector: (row) => (
        <div>
          <Link
            className="dark-link"
            to={API_URL + row.pdfPath}
            target="_blank"
            download
          >
            <CloudDownloadIcon />
          </Link>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Dropmenu
            className="dark-link"
            handleEdit={() => handleEdit(row.id)}
          // handleView={() => handleView(row.id)}
          />
        </div>
      ),
      button: false,
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-2">
            <div className="">
              <h1 className="m-0 badge badge-dark">Contract</h1>
            </div>
            <div className="">
              <Link to="/contract-add" className="btn btn-dark">
                <DocumentScannerIcon /> Create Contract
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card m-3 card-outline card-dark">
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>Error: {error?.message}</div>
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}></Grid>
              <Grid
                item
                md={6}
                xs={12}
                display="flex"
                justifyContent="flex-end"
              >
                <Search onSearch={(query) => handleSearch(query)} />
              </Grid>
            </Grid>

            <DataTable
              columns={columns}
              data={contract.response.data}
              pagination
              paginationServer
              paginationTotalRows={contract.response.pagination.total}
              paginationDefaultPage={page}
              paginationPerPage={rowsPerPage}
              onChangePage={setPage}
              onChangeRowsPerPage={(newPerPage) => {
                setRowsPerPage(newPerPage);
                setPage(1);
              }}
              customStyles={customStyles}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ContractList;
