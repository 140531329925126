import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useGetDepartmentsQuery } from '../../Redux/Service/Department';
import { useGetRolesQuery } from '../../Redux/Service/RoleApi';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useUpdateUserMutation, useGetUserByIdQuery } from '../../Redux/Service/UserApi';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function UserEdit() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ updateUser ] = useUpdateUserMutation();
    const { data: userData, isLoading: isLoadingForUser, error: errorForUser } = useGetUserByIdQuery(id);
    const { data: departmentsData, isLoading: isLoadingForDepartment, error: errorForDepartment } = useGetDepartmentsQuery();
    const { data: rolesData, isLoading: isLoadingForRole, error: errorForRole } = useGetRolesQuery();
    
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phoneNo: Yup.string().min(10, 'Phone number must be at least 10 characters').required('Phone No is required'),
        roleId: Yup.string().required('Role is required'),
        departmentId: Yup.string().required('Department is required'),
    });

    const handleSubmit = async (values, { resetForm }) => {
        try {
            values.id =  parseInt(id);
            values.roleId = parseInt(values.roleId);
            values.departmentId = parseInt(values.departmentId);
            const updateUserData = await updateUser(values);
            if(updateUserData){
                toast.success(updateUserData.data?.message);
                navigate('/user');
            }else{
                resetForm();
                toast.error('Something went wrong, Please try again later!');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Edit User</h1>
                        </div>
                        <div className="">
                            <Link to="/user" className="btn btn-dark"> <ArrowBackIcon/> Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Edit </h3>
                    <div></div>
                </div>
                <div className="card-body">
                    {isLoadingForRole || isLoadingForDepartment || isLoadingForUser ? (
                        <div>Loading...</div>
                    ) : errorForRole || errorForDepartment || errorForUser ? (
                        <div>Error: {errorForRole?.message}{errorForDepartment?.message}{errorForUser?.message}</div>
                    ) : (
                        <Formik 
                            initialValues={{
                                name: userData?.response?.name || '',
                                email: userData?.response?.email || '',
                                phoneNo: userData?.response?.phoneNo || '',
                                roleId: (userData?.response?.role && userData?.response?.role[0]?.id) || '',
                                departmentId: userData?.response?.department?.id || ''
                            }} 
                            validationSchema={validationSchema} 
                            onSubmit={handleSubmit}
                        >
                            {(
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <Field type="text" name="name" className="form-control" />
                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email address</label>
                                        <Field type="email" name="email" className="form-control" />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phoneNo">Phone No</label>
                                        <Field type="text" name="phoneNo" className="form-control" />
                                        <ErrorMessage name="phoneNo" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="roleId">Role</label>
                                        <Field as="select" name="roleId" className="form-control">
                                            <option value="">Select Role</option>
                                            {rolesData?.response.map(role => (
                                                <option key={role.id} value={role.id}>{role.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="roleId" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="departmentId">Department </label>
                                        <Field as="select" name="departmentId" className="form-control">
                                            <option value="">Select Department</option>
                                            {departmentsData?.response.map(department => (
                                                <option key={department.id} value={department.id}>
                                                    {department.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="departmentId" component="div" className="text-danger" />
                                    </div>
                                    <button type="submit" className="btn btn-dark">Submit</button>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserEdit;
