import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Grid, TextField, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import MultiSelect from '../../Components/MUI/MultiSelect';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useGetLeadsQuery } from '../../Redux/Service/LeadApi';
import { useGetProviderQuery } from '../../Redux/Service/ProviderApi';
import { useFindEstimationQuery, useUpdateEstimationMutation } from '../../Redux/Service/EstimateApi';
import { useGetTechnologyStackListQuery } from '../../Redux/Service/TechnologyStackApi';
import { toast } from 'react-toastify';
import Loading from '../../Components/Loading';


function EstimateEdit() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [techStackArr, setTechStackArr] = useState([]);
  const [uploadFile, setUploadFile] = useState('Upload Estimate File');
  const { data: estimate, isLoading: isLoadingForEstimate, error: errorForEstimate } = useFindEstimationQuery(id);
  const { data: leadData, isLoading: isLoadingForLead, isError: isErrorForLead, error: errorForLead } = useGetLeadsQuery();
  const { data: providerData, isLoading: isLoadingForProvider, isError: isErrorForProvider, error: errorForProvider } = useGetProviderQuery();
  const { data: techData, isLoading: isLoadingForTech } = useGetTechnologyStackListQuery();
  const [updateEstimation] = useUpdateEstimationMutation();

  const validationSchema = Yup.object().shape({
    leadId: Yup.number()
      .typeError('Lead ID must be a number')
      .required('Lead ID is required'),
    serviceProviderId: Yup.number()
      .typeError('Service Provider ID must be a number')
      .required('Service Provider ID is required'),
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    revisionVersion: Yup.string().required('Revision Version is required'),
    revisionComment: Yup.string().required('Revision Comment is required'),
    totalHours: Yup.number()
      .typeError('Total Hours must be a number')
      .required('Total Hours time in days is required'),
    totalCost: Yup.number()
      .typeError('Total Cost must be a number')
      .required('Total Cost is required'),
    techStack: Yup.array()
      .of(Yup.string().required('Technology is required'))
      .min(1, 'At least one technology is required'),
    file: Yup.mixed()
  });

  useEffect(() => {
    if (!isLoadingForLead && !isLoadingForProvider && !isLoadingForTech) {
      setTechStackArr(techData?.response?.map((tech)=>tech.technology));
      setLoading(false);
    }
  }, [isLoadingForLead, isLoadingForProvider, isLoadingForTech, isLoadingForEstimate, techData]);

  const handleSubmit = async (values) => {
    try {
      values.userId = 1;
      values.leadId = parseInt(values.leadId);
      values.serviceProviderId = parseInt(values.serviceProviderId);
      const updateEstimationData = await updateEstimation(values);

      if (updateEstimationData && updateEstimationData?.error) {
        toast.error(updateEstimationData?.error?.data?.message);
      } else if (updateEstimationData) {
        toast.success(updateEstimationData.data?.message);
        navigate('/estimate');
      }
    } catch (error) {
      toast.error('Something went wrong, Please try again later!');
      console.error('Login failed', error);
    } finally {
      // setSubmitting(false);
    }
  };

  const onImageChange = (event, setFieldValue) => {
      const file = event.target.files[0];
      setFieldValue('file', file);
      setUploadFile(`File :${file.name}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="d-flex justify-content-between mb-2">
            <div className="">
              <h1 className="m-0 badge badge-dark">Edit Estimation</h1>
            </div>
            <div className="">
              <Link to="/estimate" className="btn btn-dark"> <ArrowBackIcon /> Back</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-dark p-3 m-3">
        <div className="card-header">
          <h3 className="card-title">Estimation</h3>
        </div>
        {isLoadingForLead || isLoadingForProvider || isLoadingForEstimate ? (
          <div>Loading...</div>
        ) : errorForLead || errorForProvider || errorForEstimate ? (
          <div>Error: {errorForLead?.message}{errorForProvider?.message} {errorForEstimate?.message}</div>
        ) : (
          <Formik
            initialValues={{
              id: estimate?.response?.id,
              leadId: estimate?.response?.lead?.id,
              serviceProviderId: estimate?.response?.serviceProvider?.id,
              name: estimate?.response?.name,
              description: estimate?.response?.description,
              revisionVersion: estimate?.response?.revisionVersion,
              revisionComment: estimate?.response?.revisionComment,
              totalHours: estimate?.response?.totalHours,
              totalCost: estimate?.response?.totalCost,
              techStack: estimate?.response?.techStack,
              file: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
              <Form>
                <Box className="card-body" sx={{ padding: "15px 0" }}>
                  <Grid container spacing={2}>

                    <Grid item md={12} xs={12}>
                      <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                        <Grid container spacing={2}>

                          <Grid item xs={6}>
                            {isLoadingForLead ? (
                              <div>Loading...</div>
                            ) : isErrorForLead ? (
                              <div>Error: {errorForLead?.message}</div>
                            ) : (
                              <FormControl fullWidth>
                                <InputLabel id="demo-select-small-label" sx={touched.leadId && Boolean(errors.leadId) ? { color: "hwb(353deg 2% 13%)" } : {}}>Lead</InputLabel>
                                <Field
                                  label="Lead"
                                  name="leadId"
                                  as={Select}
                                  labelId="demo-select-small-lead"
                                  id="demo-simple-select-lead"
                                  onChange={(event) => setFieldValue('leadId', event.target.value)}
                                  error={touched.leadId && Boolean(errors.leadId)}
                                  helperText={touched.leadId && errors.leadId}
                                >
                                  {leadData?.response?.map((val, index) => (
                                    <MenuItem key={index} value={val.id}>{val.name}</MenuItem>
                                  ))}
                                </Field>
                                <ErrorMessage name="leadId" className='error-message' component="div" style={{ color: 'red' }} />
                              </FormControl>
                            )}
                          </Grid>

                          <Grid item xs={6}>
                            {isLoadingForProvider ? (
                              <div>Loading...</div>
                            ) : isErrorForProvider ? (
                              <div>Error: {errorForProvider?.message}</div>
                            ) : (
                              <FormControl fullWidth>
                                <InputLabel id="demo-select-small-label" sx={touched.serviceProviderId && Boolean(errors.serviceProviderId) ? { color: "hwb(353deg 2% 13%)" } : {}}>Service Provider</InputLabel>
                                <Field
                                  label="Service Provider"
                                  name="serviceProviderId"
                                  as={Select}
                                  labelId="demo-select-small-label"
                                  id="demo-simple-select"
                                  onChange={(event) => setFieldValue('serviceProviderId', event.target.value)}
                                  error={touched.serviceProviderId && Boolean(errors.serviceProviderId)}
                                  helperText={touched.serviceProviderId && errors.serviceProviderId}
                                >
                                  {providerData?.response?.map((val, index) => (
                                    <MenuItem key={index} value={val.id}>{val.name}</MenuItem>
                                  ))}
                                </Field>
                                <ErrorMessage name="serviceProviderId" className='error-message' component="div" style={{ color: 'red' }} />
                              </FormControl>
                            )}
                          </Grid>

                          <Grid item xs={3}>
                            <Field
                              fullWidth
                              label="Name"
                              name="name"
                              variant="outlined"
                              type="text"
                              as={TextField}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                            />
                          </Grid>

                          <Grid item xs={9}>
                            <Field
                              fullWidth
                              label="Revision Version"
                              name="revisionVersion"
                              variant="outlined"
                              type="text"
                              as={TextField}
                              error={touched.revisionVersion && Boolean(errors.revisionVersion)}
                              helperText={touched.revisionVersion && errors.revisionVersion}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Field
                              fullWidth
                              label="Total Hours"
                              name="totalHours"
                              variant="outlined"
                              type="number"
                              as={TextField}
                              error={touched.totalHours && Boolean(errors.totalHours)}
                              helperText={touched.totalHours && errors.totalHours}
                            />
                          </Grid>

                          <Grid item xs={9}>
                            <Field
                              fullWidth
                              label="Revision Comment"
                              name="revisionComment"
                              variant="outlined"
                              type="text"
                              as={TextField}
                              error={touched.revisionComment && Boolean(errors.revisionComment)}
                              helperText={touched.revisionComment && errors.revisionComment}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Field
                              fullWidth
                              label="Total Cost"
                              name="totalCost"
                              variant="outlined"
                              type="number"
                              as={TextField}
                              error={touched.totalCost && Boolean(errors.totalCost)}
                              helperText={touched.totalCost && errors.totalCost}
                            />
                          </Grid>

                          <Grid item xs={9}>
                            <Field
                              label="Tech Stack"
                              name="techStack"
                              variant="outlined"
                              as={Select}
                              render={() => (
                                <MultiSelect
                                  options={techStackArr}
                                  selectedOptions={values.techStack}
                                  onChange={selectedOptions => setFieldValue("techStack", selectedOptions)}
                                  placeholder="Select Tech Stack"
                                />
                              )}
                              error={touched.techStack && Boolean(errors.techStack)}
                              helperText={touched.techStack && errors.techStack}
                            />
                            <ErrorMessage name="techStack" className='error-message invalid-feedback' component="div" />
                          </Grid>

                          <Grid item md={12} xs={12}>
                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                              <Typography variant="h6" gutterBottom align="center">
                                Description
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Field name="description">
                                    {({ field }) => (
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={field.value}
                                        onChange={(event, editor) => setFieldValue('description', editor.getData())}
                                      />
                                    )}
                                  </Field>
                                  <ErrorMessage name="description" className="error-message" component="div"/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <div className="file-input">
                              <label htmlFor="file" className="file-label">{uploadFile}</label>
                              <input
                                id="file"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={(event) => onImageChange(event, setFieldValue)}
                              />
                              <ErrorMessage name="file" component="div" className="error-message" />
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                  </Grid>
                  <button
                    type="submit"
                    className="btn btn-dark mt-2"
                    disabled={isSubmitting}
                  >{isSubmitting ? 'Logging in...' : 'Submit'}</button>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
export default EstimateEdit;