import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import propsType from 'prop-types'; 

const Search = (props) => {

    const { onSearch } = props;
 
    const handleSearchChange = (event) => {
        const query = event.target.value;
        onSearch(query);
    };

    return (
        <FormControl sx={{ m: 1.5, minWidth: '300px' }} variant="standard">
            <Input
                id="standard-adornment-password"
                placeholder='Please search here...'
                type='text'
                onChange={handleSearchChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
                sx={{
                    padding: '10px',
                }}
            />
        </FormControl>
    );
}

Search.propTypes = {
    onSearch : propsType.func
}
  
export default Search;