function Footer() {
    return (
        <>
            <div>
                <footer className="main-footer">
                    <strong>Copyright © 2024 <a href="https://pitangent.com/">Pitangent</a> </strong>
                    All rights reserved.
                    <div className="float-right d-none d-sm-inline-block">
                        <b>Version</b> 1.0.0
                    </div>
                </footer>
                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>

        </>
    )
}

export default Footer
