/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import {
  Grid,
  TextField,
  Box,
  Typography,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function StepThree({
  isSubmitting,
  values,
  setFieldValue,
  touched,
  errors,
  initialValues,
}) {
  // Redux States
  const technologyData = useSelector(
    (state) => state.contractState.technologyStacks
  );

  const [technologyOptions, setTechnologyOptions] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);

  const handleRemoveItem = (id, arrayHelpers) => {
    arrayHelpers.remove(id);
  };

  useEffect(() => {
    // Fetching technology options (you could fetch from an API in real implementation)
    setTechnologyOptions(technologyData);
    setSelectedTechnologies(values.proposed_technology_stack.map((name) => {
      return technologyData.find((item) => item.technology === name);
    }).map((item) => item.technology));
  }, [selectedTechnologies]);

  const handleAddTechnology = (technologyId) => {
    const selectedTechnology = technologyData.find(item => item.id === technologyId);
        setSelectedTechnologies(prev => [...prev, selectedTechnology.technology]);
        setFieldValue('proposed_technology_stack', [...selectedTechnologies, selectedTechnology.technology]);
};


  const handleRemoveTechnology = (technology) => {
    const updatedTechnologies = selectedTechnologies.filter(
      (item) => item !== technology
    );
    setSelectedTechnologies(updatedTechnologies);
    setFieldValue("proposed_technology_stack", updatedTechnologies);
  };

  return (
    <>
      <div className="card card-dark p-3 m-3">
        <div className="card-header">
          <h3 className="card-title">Technology Stack</h3>
        </div>
        <Box className="card-body" sx={{ padding: "15px 0" }}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                <Typography variant="h6" gutterBottom align="left">
                  Proposed Technology Stack
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      label="Select Technology"
                      onChange={(e) => handleAddTechnology(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Technology
                      </MenuItem>
                      {technologyOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.technology}
                        </MenuItem>
                      ))}
                    </Select>

                    <ErrorMessage
                      name="proposed_technology_stack"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Selected Technologies:
                    </Typography>
                    <Box>
                      {selectedTechnologies?.map((tech, index) => (
                        <Box
                          key={index}
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <Typography variant="body2">{tech}</Typography>
                          <Button
                            onClick={() => handleRemoveTechnology(tech)}
                            startIcon={<DeleteIcon />}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            Remove
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Team Structure Section (remains unchanged) */}
            <Grid item md={12} xs={12}>
              <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                <Typography variant="h6" gutterBottom align="left">
                  Team Structure
                </Typography>
                <FieldArray name="team_structure">
                  {(arrayHelpers) => (
                    <div>
                      {values?.team_structure?.map((val, index) => (
                        <Grid container spacing={2} key={val.id} sx={{ my: 1 }}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={2}>
                                {index === 0 ? (
                                  <Grid item xs={12}>
                                    <Button
                                      onClick={() =>
                                        arrayHelpers.push({
                                          id: values.team_structure.length + 1,
                                          member: "",
                                          engagement_team: "",
                                          no_of_team_members: 0,
                                        })
                                      }
                                      startIcon={<AddIcon />}
                                      variant="contained"
                                    >
                                      Add More
                                    </Button>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12}>
                                    <Button
                                      onClick={() =>
                                        handleRemoveItem(index, arrayHelpers)
                                      }
                                      startIcon={<DeleteIcon />}
                                      variant="contained"
                                      color="error"
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>

                              <Grid item xs={1}></Grid>

                              <Grid item xs={5}>
                                <Field
                                  fullWidth
                                  label="Team Name"
                                  name={`team_structure.${index}.member`}
                                  value={val.member}
                                  variant="outlined"
                                  as={TextField}
                                  error={
                                    touched.team_structure?.[index]?.member &&
                                    Boolean(
                                      errors.team_structure?.[index]?.member
                                    )
                                  }
                                  helperText={
                                    touched.team_structure?.[index]?.member &&
                                    errors.team_structure?.[index]?.member
                                  }
                                />
                              </Grid>

                              <Grid item xs={2}>
                                <Field
                                  fullWidth
                                  label="Engagement Team"
                                  name={`team_structure.${index}.engagement`}
                                  variant="outlined"
                                  type="text"
                                  as={TextField}
                                  error={
                                    touched.team_structure?.[index]
                                      ?.engagement &&
                                    Boolean(
                                      errors.team_structure?.[index]
                                        ?.engagement
                                    )
                                  }
                                  helperText={
                                    touched.team_structure?.[index]
                                      ?.engagement &&
                                    errors.team_structure?.[index]
                                      ?.engagement
                                  }
                                />
                              </Grid>

                              <Grid item xs={2}>
                                <Field
                                  fullWidth
                                  label="No. of Team Members"
                                  name={`team_structure.${index}.count`}
                                  variant="outlined"
                                  type="text"
                                  as={TextField}
                                  error={
                                    touched.team_structure?.[index]
                                      ?.count &&
                                    Boolean(
                                      errors.team_structure?.[index]
                                        ?.count
                                    )
                                  }
                                  helperText={
                                    touched.team_structure?.[index]
                                      ?.count &&
                                    errors.team_structure?.[index]
                                      ?.count
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  )}
                </FieldArray>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default StepThree;
