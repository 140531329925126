import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './Service/AuthApi';
import { userApi } from './Service/UserApi';
import AuthSilice from './Reducers/Auth/AuthSilice';
import ContractSlice from './Reducers/Contracts/ContractSlice';
import Storage from './Stroage';
import { departmentApi } from './Service/Department';
import { roleApi } from './Service/RoleApi';
import { quotationApi } from './Service/QuotationApi';
import { estimateApi } from './Service/EstimateApi';
import { currencyApi } from './Service/CurrencyApi';
import { leadApi } from './Service/LeadApi';
import { providerApi } from './Service/ProviderApi';
import { technologyStackApi } from './Service/TechnologyStackApi';
import { contractApi } from './Service/ContractApi';

const Store = configureStore({
    reducer: {
		authState: AuthSilice,
		contractState: ContractSlice,
        [authApi.reducerPath]: authApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[departmentApi.reducerPath]: departmentApi.reducer,
		[roleApi.reducerPath]: roleApi.reducer,
		[quotationApi.reducerPath]: quotationApi.reducer,
		[estimateApi.reducerPath]: estimateApi.reducer,
		[currencyApi.reducerPath]: currencyApi.reducer,
		[leadApi.reducerPath]: leadApi.reducer,
		[providerApi.reducerPath]: providerApi.reducer,
		[technologyStackApi.reducerPath]: technologyStackApi.reducer,
		[contractApi.reducerPath]:contractApi.reducer,
    },
	preloadedState:Storage.LoadState(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false
		})
		.concat([authApi.middleware])
		.concat([userApi.middleware])
		.concat([departmentApi.middleware])
		.concat([roleApi.middleware])
		.concat([quotationApi.middleware])
		.concat([estimateApi.middleware])
		.concat([currencyApi.middleware])
		.concat([leadApi.middleware])
		.concat([providerApi.middleware])
		.concat([technologyStackApi.middleware])
		.concat([contractApi.middleware]),

		devTools: String(process.env.NODE_ENV) !== 'production',
});

Store.subscribe(() => {
	Storage.SaveState(Store.getState());
});

export default Store;