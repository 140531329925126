import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../Config';
import { logout } from '../Reducers/Auth/AuthSilice';

export function getAuthToken() {
    const storage = JSON.parse(localStorage.getItem('state') || '');
    return storage?.token;
}

export const FetchBaseQueryWithHeaderToken = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
        const token = getAuthToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
})

// export const baseQueryWithReauth = async (args, api, extraOptions) => {
//     let result = await FetchBaseQueryWithHeaderToken(args, api, extraOptions);

//     if (result.error && result.error.status === 401) {
//         api.dispatch(logout());
//     }

//     return result;
// };

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await FetchBaseQueryWithHeaderToken(args, api, extraOptions);

    if (result.error && result.error.status === 'FETCH_ERROR') {
        api.dispatch(logout());
    }

    if (result.error && result.error.status === 401) {
        api.dispatch(logout());
    }

    return result;
};

export const FetchBaseQuery =  fetchBaseQuery({baseUrl: API_URL});