import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const estimateApi = createApi({
    reducerPath: 'estimateApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Estimate'],
    endpoints: (builder) => ({
        getEstimateList: builder.query({
            query: () => ({
                url: `/estimate`,
                method: 'GET',
            })
        }),

        getEstimation: builder.query({
            query: ({ limit = 10, page = 0, orderBy = 'DESC', search = '' }) => ({
                url: `estimate/list?limit=${limit}&page=${page}&orderBy=${orderBy}&search=${search}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Quotation', id: 'LIST' }],
        }),

        findEstimation: builder.query({
            query: (id) => ({
                url: `estimate/view/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Estimate', id }],
        }),

        createEstimation: builder.mutation({
            query: (req) => {
                const formData = new FormData();

                Object.entries(req).forEach(([key, value]) => {
                    if (key === 'file' && value) {
                        formData.append('file', value);
                    } else if (key === 'techStack' && value) {
                        value.forEach(item => formData.append(key, item));
                    } else {
                        formData.append(key, value);
                    }
                });

                // for (const key in req) {
                //     if (key === 'file' && req[key]) {
                //         formData.append('file', req[key]);
                //     }else if(key === 'techStack' && req[key]) {
                //         formData.append(key, [...req[key]]);
                //     }else{
                //         formData.append(key, req[key]);
                //     }
                // }

                return {
                    url: 'estimate/create',
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: [{ type: 'Estimate', id: 'LIST' }],
        }),

        updateEstimation: builder.mutation({
            query: (req) => {
                const formData = new FormData();

                Object.entries(req).forEach(([key, value]) => {
                    if (key === 'file' && value) {
                        formData.append('file', value);
                    } else if (key === 'techStack' && value) {
                        value.forEach(item => formData.append(key, item));
                    } else {
                        formData.append(key, value);
                    }
                });

                return {
                    url: `estimate/update/${req.id}`,
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Estimate', id }],
        }),
    }),
});

export const { 
    useGetEstimateListQuery, 
    useGetEstimationQuery, 
    useCreateEstimationMutation, 
    useUpdateEstimationMutation,
    useFindEstimationQuery
} = estimateApi;