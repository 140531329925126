/* eslint-disable no-unused-vars */
import React from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import { Grid, TextField, Box, Typography } from "@mui/material";
// import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function StepFour({
  isSubmitting,
  values,
  setFieldValue,
  touched,
  errors,
  initialValues,
}) {
  // Calculate Tax Amount and Total Amount Including Tax
  const calculatedTaxAmount = (values.total_development_cost * (values.payable_tax_percentage || 0)) / 100;
  const totalAmountIncludingTax = Number(values.total_development_cost) + Number(calculatedTaxAmount);
  // Redux State for Contract Prefilled Data
  const currencyDetails = useSelector((state) => state.contractState?.contractCurrency);
  console.log("currencyDetails", currencyDetails);

  return (
    <>
      <div className="card card-dark p-3 m-3">
        <div className="card-header">
          <h3 className="card-title">Commercial Details</h3>
        </div>
        <Box className="card-body" sx={{ padding: "15px 0" }}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Box
                      sx={{
                        border: "1px solid grey",
                        borderRadius: "8px",
                        p: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="left">
                        Commercial Details
                      </Typography>
                      <Grid container spacing={2} sx={{ my: 1 }}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Field
                                fullWidth
                                label={currencyDetails ? `Total Development Cost (in ${currencyDetails?.code})` : `Total Development Cost`}
                                name="total_development_cost"
                                variant="outlined"
                                type="number"
                                value={values.total_development_cost}
                                onChange={(e) => {
                                  setFieldValue(
                                    "total_development_cost",
                                    e.target.value
                                  );
                                }}
                                as={TextField}
                                error={
                                  touched.total_development_cost &&
                                  Boolean(errors.total_development_cost)
                                }
                                helperText={
                                  touched.total_development_cost &&
                                  errors.total_development_cost
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                fullWidth
                                label="Payable Tax Percentage (%)"
                                name="payable_tax_percentage"
                                variant="outlined"
                                type="text"
                                value={values.payable_tax_percentage}
                                onChange={(e) => {
                                  const value = Math.min(e.target.value, 100);
                                  setFieldValue(
                                    "payable_tax_percentage",
                                    value
                                  );
                                }}
                                as={TextField}
                                error={
                                  touched.payable_tax_percentage &&
                                  Boolean(errors.payable_tax_percentage)
                                }
                                helperText={
                                  touched.payable_tax_percentage &&
                                  errors.payable_tax_percentage
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2" gutterBottom>
                                Tax Amount
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ width: '100%', padding: '8px', border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#f5f5f5' }}
                              >
                                {currencyDetails ? `(${currencyDetails?.code}) ${calculatedTaxAmount}` : calculatedTaxAmount}
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography variant="subtitle2" gutterBottom>
                                Total Amount Including Tax
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ width: '100%', padding: '8px', border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#f5f5f5' }}
                              >
                                {currencyDetails ? `(${currencyDetails?.code}) ${totalAmountIncludingTax}` : totalAmountIncludingTax}
                              </Typography>
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}



export default StepFour;
