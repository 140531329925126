import { createSlice } from "@reduxjs/toolkit";

const contractSlice = createSlice({
  name: "contract",
  initialState: {
    prefilledData: null,
    technologyStacks: null,
    leads: null,
    quotation: null,
    bankDetails: null,
    contractUpdatePrefilledData: null,
    contractCurrency: null,
  },
  reducers: {
    setContractPrefilledData: (state, action) => {
      state.prefilledData = action.payload;
    },
    setTechnologyStacks: (state, action) => {
      state.technologyStacks = action.payload;
    },
    setLeads: (state, action) => {
      state.leads = action.payload;
    },
    setQuotation: (state, action) => {
      state.quotation = action.payload;
    },
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setContractUpdatePrefilledData: (state, action) => {
      state.contractUpdatePrefilledData = action.payload;
    },
    setContractCurrency: (state, action) => {
      state.contractCurrency = action.payload;
    }
  },
});

export const { setContractPrefilledData, setTechnologyStacks, setLeads, setQuotation, setBankDetails, setContractUpdatePrefilledData, setContractCurrency } = contractSlice.actions;

export default contractSlice.reducer;
