import { Navigate, Routes, Route } from 'react-router-dom'
import propsType from 'prop-types'; 
function AuthLayout(props) {
  const { childRoutes } = props;
  return (
    <div className='auth-layout'>
      <Routes>
        {childRoutes && childRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
      <Navigate to='/login'/>
    </div>
  )
}

AuthLayout.propTypes = {
  childRoutes : propsType.array.isRequired
}

export default AuthLayout
