/* eslint-disable no-unused-vars */
import React from 'react';
import { Field, ErrorMessage, FieldArray } from 'formik';
import { Grid, TextField, Box, Typography } from '@mui/material';
import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function StepTwo({ isSubmitting, values, setFieldValue, touched, errors, initialValues }) {

    const handleRemoveItem = (id, arrayHelpers) => {
        arrayHelpers.remove(id);
    };

    // Calculate total cost of all milestones
    const totalMilestoneCost = values.deliverable_milestones.reduce((sum, milestone) => sum + (milestone.cost || 0), 0);

    return (
        <div className="card card-dark p-3 m-3">
            <div className="card-header">
                <h3 className="card-title">Project Development Time</h3>
            </div>
            <Box className="card-body" sx={{ padding: "15px 0" }}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Project Development Time
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="project_development">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('project_development', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="project_development" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Deliverable Milestones
                                        </Typography>
                                        <FieldArray name="deliverable_milestones">
                                            {arrayHelpers => (
                                                <div>
                                                    {values?.deliverable_milestones?.map((val, index) => (
                                                        <Grid container spacing={2} key={val.id} sx={{ my: 1 }}>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={2}>
                                                                        {index === 0 ? (
                                                                            <Grid item xs={12}>
                                                                                <button
                                                                                    type='button'
                                                                                    className="btn btn-sm"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.push({
                                                                                            id: values.deliverable_milestones.length + 1,
                                                                                            type: 'Days',
                                                                                            milestone: 'Milestone ' + (values.deliverable_milestones.length + 1),
                                                                                            delivery: 0,
                                                                                            cost: 0,
                                                                                        })}
                                                                                ><AddIcon /> Add More</button>
                                                                            </Grid>
                                                                        ) : (
                                                                            <Grid item xs={12}>
                                                                                <button type='button' className="btn btn-sm" onClick={() => handleRemoveItem(index, arrayHelpers)}><DeleteIcon /> Delete</button>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>

                                                                    <Grid item xs={1}></Grid>

                                                                    <Grid item xs={3}>
                                                                        <Field
                                                                            fullWidth
                                                                            label="Milestone"
                                                                            name={`deliverable_milestones.${index}.milestone`}
                                                                            value={values.deliverable_milestones[index].milestone}
                                                                            variant="outlined"
                                                                            as={TextField}
                                                                            error={touched.deliverable_milestones?.[index]?.milestone && Boolean(errors.deliverable_milestones?.[index]?.milestone)}
                                                                            helperText={touched.deliverable_milestones?.[index]?.milestone && errors.deliverable_milestones?.[index]?.milestone}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Field
                                                                            fullWidth
                                                                            label="Delivery"
                                                                            name={`deliverable_milestones.${index}.delivery`}
                                                                            variant="outlined"
                                                                            type="number"
                                                                            value={values.deliverable_milestones[index].delivery}
                                                                            as={TextField}
                                                                            error={touched.deliverable_milestones?.[index]?.delivery && Boolean(errors.deliverable_milestones?.[index]?.delivery)}
                                                                            helperText={touched.deliverable_milestones?.[index]?.delivery && errors.deliverable_milestones?.[index]?.delivery}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Field
                                                                            fullWidth
                                                                            label="Type"
                                                                            name={`deliverable_milestones.${index}.type`}
                                                                            variant="outlined"
                                                                            type="text"
                                                                            value={values.deliverable_milestones[index].type}
                                                                            as={TextField}
                                                                            helperText={touched.deliverable_milestones?.[index]?.type && errors.deliverable_milestones?.[index]?.type}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={2}>
                                                                        <Field
                                                                            fullWidth
                                                                            label="Cost (USD)"
                                                                            name={`deliverable_milestones.${index}.cost`}
                                                                            variant="outlined"
                                                                            type="number"
                                                                            value={values.deliverable_milestones[index].cost}
                                                                            as={TextField}
                                                                            error={touched.deliverable_milestones?.[index]?.cost && Boolean(errors.deliverable_milestones?.[index]?.cost)}
                                                                            helperText={touched.deliverable_milestones?.[index]?.cost && errors.deliverable_milestones?.[index]?.cost}
                                                                        />
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    {/* Total Cost Field */}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Total Milestone Cost (USD)"
                                                            value={totalMilestoneCost.toFixed(2)}
                                                            variant="outlined"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}


export default StepTwo;
