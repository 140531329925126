/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Grid, Box, Typography, MenuItem, Select } from '@mui/material';
import CustomDatePicker from '../../../Components/MUI/CustomDatePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';
import { setContractCurrency } from '../../../Redux/Reducers/Contracts/ContractSlice';

function StepOneUpdate({ isSubmitting, values, setFieldValue, touched, errors, initialValues }) {
    // Redux State for Contract Prefilled Data
    const contractPrefilledData = useSelector((state) => state.contractState.contractUpdatePrefilledData);
    const contractData = useSelector((state) => state.contractState);
    const [leadId, setLeadId] = useState('');
    const dispatch = useDispatch();

    // Set Initial Values
    React.useEffect(() => {
        if (contractPrefilledData) {
            setFieldValue('confidential_information', contractPrefilledData?.confidentialInfo);
            setFieldValue('purpose_of_the_agreement', contractPrefilledData?.purposeAgreement);
            setFieldValue('amendments', contractPrefilledData?.amendment);
            setFieldValue('scope_of_work', contractPrefilledData?.scopeWork);
            setLeadId(contractPrefilledData.lead.id);
            setFieldValue('lead_id', contractPrefilledData?.lead?.id);
            setFieldValue('quotation_id', contractPrefilledData?.quotation?.id);
            dispatch(setContractCurrency(contractData.quotation.find((quotation) => quotation.id === contractPrefilledData?.quotation?.id)?.currency));
            setFieldValue('total_development_cost', contractPrefilledData?.billableAmount);
            setFieldValue('payable_tax_percentage', (contractPrefilledData?.taxAmount / contractPrefilledData?.billableAmount) * 100);
            setFieldValue('project_development', contractPrefilledData?.developementTime);
        }
    }, [contractPrefilledData, setFieldValue, contractData.quotation, dispatch]);

    // Handle Add Leads
    const handleAddLeads = (leadId) => {
        setFieldValue('lead_id', leadId);
    }

    // Handle Add Quotation
    const handleAddQuotation = (quotationId) => {
        setFieldValue('quotation_id', quotationId);
        dispatch(setContractCurrency(contractData.quotation.find((quotation) => quotation.id === quotationId)?.currency));
    }

    return (
        <div className="card card-dark p-3 m-3">
            <div className="card-header">
                <h3 className="card-title">Contract Setup</h3>
            </div>
            <Box className="card-body" sx={{ padding: "15px 0" }}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                            <Typography variant="h6" gutterBottom align="left">
                                Select Lead
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        fullWidth
                                        label="Select Lead"
                                        onChange={(e) => handleAddLeads(e.target.value)}
                                        defaultValue={contractPrefilledData?.lead?.id}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select Lead
                                        </MenuItem>
                                        {contractData?.leads.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <ErrorMessage
                                        name="Select_Lead"
                                        component="div"
                                        style={{ color: "red" }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
                            <Typography variant="h6" gutterBottom align="left">
                                Select Quotation
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        fullWidth
                                        label="Select Quotation"
                                        onChange={(e) => handleAddQuotation(e.target.value)}
                                        defaultValue={contractPrefilledData?.quotation?.id}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select Quotation
                                        </MenuItem>
                                        {contractData.quotation.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                Quotation Id: {option.uniqueId}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <ErrorMessage
                                        name="proposed_technology_stack"
                                        component="div"
                                        style={{ color: "red" }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} align="right">
                                    <CustomDatePicker
                                        label="Date"
                                        name="date"
                                        onChange={(date) => setFieldValue('date', date)}
                                    />
                                    <ErrorMessage name="date" component="div" style={{ color: 'red' }} />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Confidential Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="confidential_information">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('confidential_information', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="confidential_information" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Purpose of the Agreement
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="purpose_of_the_agreement">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('purpose_of_the_agreement', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="purpose_of_the_agreement" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Scope of work
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="scope_of_work">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('scope_of_work', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="scope_of_work" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Amendments
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="amendments">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('amendments', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="amendments" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default StepOneUpdate;
