import { useDispatch } from 'react-redux';
import { logout } from '../Redux/Reducers/Auth/AuthSilice';
import { LuLogOut } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { Link } from 'react-router-dom';

function Header() {

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to="#" className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars" /></Link>
                    </li>
                </ul>
                <div className="ml-auto">
                    <div className="nav-item dropdown usermenu">
                        <Link to="#" data-toggle="dropdown">
                            <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <Link to="/profile" className="dropdown-item">
                                <CgProfile className='icon-size'/> Password Change
                            </Link>
                            <button className="dropdown-item" onClick={handleLogout}>
                                <LuLogOut className='icon-size'/> Logout
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            {/* /.navbar */}
        </>
    )
}

export default Header
