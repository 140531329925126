import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import { API_URL } from './Config';


function App() {
  const state = useSelector((state) => state);
  const isAuthorized = state?.authState?.isAuthorized;
  return (
    <div className='App'>
      <ToastContainer />
      <Routes>
        {!isAuthorized && routes.filter((rt) => !rt.isAuthorized).map((noAuthRoute, routeIndex) => (
          <Route 
            key={routeIndex} 
            path={noAuthRoute.path} 
            element={<noAuthRoute.layout childRoutes={noAuthRoute.routes}  />} 
          />
        ))}
        {isAuthorized && routes.filter((rt) => rt.isAuthorized).map((noAuthRoute, routeIndex) => (
          <Route 
            key={routeIndex} 
            path={noAuthRoute.path} 
            element={<noAuthRoute.layout childRoutes={noAuthRoute.routes}  />} 
          />
        ))}
      </Routes>
    </div>
  )
}

export default App