import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const technologyStackApi = createApi({
    reducerPath: 'technologyStackApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['TechnologyStack'],
    endpoints: (builder) => ({
        getTechnologyStackList: builder.query({
            query: () => ({
                url: `/technology-stack/list-technology`,
                method: 'GET',
            })
        }),
    }),
});

export const { useGetTechnologyStackListQuery } = technologyStackApi;