
let storage = localStorage;
// if (process.env.NODE_ENV === 'development') storage = sessionStorage;

export default class Storage {
    static LoadState() {
        try {
            const sessionState = storage.getItem('state') || {};
            const parsedState = sessionState ? JSON.parse(sessionState) : {};
            const validState = {
                authState: {
                    isAuthorized: parsedState.isAuthorized || false,
                    user: parsedState.user || null,
                    token: parsedState.token || null
                }
            };
            return validState;
        } catch (err) {
            return Object.assign({}, {});
        }
    }

    static SaveState(state) {
       
        try {
            storage.clear();
            const sessionState = {
                isAuthorized: state.authState.isAuthorized,
                user: state.authState.user,
                token: state.authState.token
            };
            storage.setItem('state', JSON.stringify(sessionState));
        } catch (err) {
            console.error(err);
        }
    }
}
