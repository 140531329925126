/* eslint-disable no-unused-vars */
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import axios from "axios";
import { API_URL } from "../../Config";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";
import { getAuthToken } from "../../Redux/Service/FetchBaseQuery";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

function ContractAdd() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false); // Loading state

  // Redux States
  const quotations = useSelector((state) => state.contractState.quotation);
  const signaturePath = useSelector((state) => state.contractState.prefilledData.response[7].data.signature);

  const steps = [
    "Contract Setup",
    "Commercial Details",
    "Technology Stack",
    "Project Development Time",
    "Bank Details",
    "Terms & Conditions",
    "Upload Document",
  ];

  let initialValues = {
    date: new Date(),
    confidential_information: ``,
    lead_id: "",
    quotation_id: "",
    purpose_of_the_agreement: "",
    scope_of_work: "",
    amendments: "",
    project_development: "",
    deliverable_milestones: [
      {
        id: 1,
        milestone: "",
        type: "Days",
        delivery: 0,
        cost: 0,
      },
    ],
    proposed_technology_stack: [],
    team_structure: [
      {
        id: 1,
        member: "",
        engagement: "",
        count: 0,
      },
    ],
    total_development_cost: 0,         // New field
    payable_tax_percentage: 0,         // New field
    Bank_Details: [],
    general_terms_and_conditions: "",
    project_termination_terms: "",
    payment_terms: "",
    application_ownership: "",
    liability: "",
    legal_jurisdiction: "",
    uploadedFile: [""],
    signature: "",
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("Date is required"),
  });

  const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSubmit = async (values) => {

    // // check if the form is complete
    if (isObjectEmptyExceptSignature(values) === false) {
      toast.error("Please fill all the fields");
      return;
    }



    // check if user has uploaded any file
    if (values.uploadedFile.length === 0) {
      return;
    }


    // Create a new contract formData
    const contractFormData = new FormData();


    // check that if total Milestone cost is matching with the total cost of Commercial Details with GST Percentage
    const calculatedTaxAmount = (values.total_development_cost * (values.payable_tax_percentage || 0)) / 100;
    const totalAmountIncludingTax = Number(values.total_development_cost) + Number(calculatedTaxAmount);

    if (
      values.deliverable_milestones.reduce(
        (acc, curr) => acc + curr.cost,
        0
      ) !==
      totalAmountIncludingTax
    ) {
      toast.error("Total Milestone cost should match with the total cost of Commercial Details including tax");
      return
    }

    // Append the values to the formData
    contractFormData.append("effectiveDate", JSON.stringify(values.date)); JSON.stringify(signaturePath)
    contractFormData.append("signature", values.signature || JSON.stringify(signaturePath));
    contractFormData.append("lead", JSON.stringify(values.lead_id));
    contractFormData.append("quotation", JSON.stringify(values.quotation_id));
    contractFormData.append(
      "confidentialInfo",
      values.confidential_information
    );
    contractFormData.append(
      "purposeAgreement",
      values.purpose_of_the_agreement
    );
    contractFormData.append("scopeWork", values.scope_of_work ? values.scope_of_work.replace(/<[^>]+>/g, "") : "");
    contractFormData.append("amendment", values.amendments);
    contractFormData.append("project_development", values.project_development);
    contractFormData.append(
      "technologyStack",
      JSON.stringify(values.proposed_technology_stack)
    );
    contractFormData.append("team", JSON.stringify(values.team_structure));
    contractFormData.append(
      "commercial_details",
      JSON.stringify(values.commercial_details)
    );
    contractFormData.append(
      "milestone",
      JSON.stringify(values.deliverable_milestones)
    );
    contractFormData.append("bank", JSON.stringify(values.Bank_Details));
    contractFormData.append(
      "general_term",
      JSON.stringify(
        values.general_terms_and_conditions
          ?.replace(/<[^>]+>/g, "")
          .split(".")
      )
    );
    contractFormData.append(
      "taxAmount",
      JSON.stringify(
        calculatedTaxAmount
      ))
    contractFormData.append(
      "termination_term",
      JSON.stringify(values.project_termination_terms?.replace(/<[^>]+>/g, "") // This regex removes all HTML tags
        .split("."))
    );
    contractFormData.append(
      "payment_term",
      JSON.stringify(values.payment_terms?.replace(/<[^>]+>/g, "") // This regex removes all HTML tags
        .split("."))
    );
    contractFormData.append("app_ownership", values.application_ownership);
    contractFormData.append("liability", values.liability);
    contractFormData.append("legal_jurisdiction", values.legal_jurisdiction);
    contractFormData.append(
      "billableAmount",
      JSON.stringify(
        values.deliverable_milestones?.reduce((acc, curr) => acc + curr.cost, 0) - calculatedTaxAmount
      )
    );
    contractFormData.append(
      "developementTime",
      JSON.stringify(values.project_development?.replace(/<[^>]+>/g, "")
    ));

    for (let i = 0; i < values.uploadedFile.length; i++) {
      if (values.uploadedFile[i]) {
        contractFormData.append(`image${i}`, values.uploadedFile[i]);
      }
    }
    try {
      setLoading(true); // Start loading
      const token = getAuthToken();
      const response = await axios.post(
        `${API_URL}contract/authenticate/create`,
        contractFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            token,
          },
        }
      );

      // Send PDF Generate Request to the server
      await axios.post(
        `${API_URL}contract/generate/${response.data.response.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            token,
          },
        }
      );

      setLoading(false); // Stop loading

      toast.success("Contract created successfully");
      navigate("/contract")
    } catch (error) {
      setLoading(false); // Stop loading
      toast.error(error.response.data.message);
    }
  };


  const StepContent = ({
    isSubmitting,
    values,
    setFieldValue,
    touched,
    errors,
    initialValues,
  }) => {
    switch (activeStep) {
      case 0:
        return (
          <StepOne
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      case 1:
        return (
          <StepFour
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      case 2:
        return (
          <StepThree
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      case 3:
        return (
          <StepTwo
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      case 4:
        return (
          <StepFive
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      case 5:
        return (
          <StepSix
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      case 6:
        return (
          <StepSeven
            {...{
              isSubmitting,
              values,
              setFieldValue,
              touched,
              errors,
              initialValues,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      {loading ? (
        <div className="loading-screen">
          <CircularProgress /> {/* Show loading spinner */}
          <p>Processing your request. Please wait...</p>
        </div>
      ) : (
        <>
          <div className="content-header">
            <div className="container-fluid">
              <div className="d-flex justify-content-between mb-2">
                <h1 className="m-0 badge badge-dark">Add Contract</h1>
                <Link to="/contract" className="btn btn-dark">
                  <ArrowBackIcon /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="card card-dark p-3 m-3">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep - 1}>
                {steps?.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps} onClick={() => {
                      if (index < activeStep - 1) {
                        setActiveStep(index + 1); // Move to the clicked step
                        setSkipped(new Set()); // Reset the skipped steps
                      }
                    }}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                // onSubmit={handleSubmit}
                onReset={() => setActiveStep(0)}
              >
                {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                  <Form>
                    <StepContent
                      {...{
                        isSubmitting,
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        initialValues,
                      }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStep === steps.length - 1 ? (
                        <Button type="button" onClick={() => {
                          handleSubmit(values)
                        }}>Submit</Button>
                      ) : (
                        <Button type="button" onClick={handleNext}>Next</Button>
                      )}
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </div>
        </>
      )}


    </div>
  );
}

export default ContractAdd;


function isObjectEmptyExceptSignature(obj) {
  for (let key in obj) {
    // Skip 'signature' field
    if (key === "signature") continue;

    const value = obj[key];

    // Check if the value is not a non-empty string
    if (typeof value == "string" && value.trim() === "") {
      console.log("key", key);
      return false;
    }

    if (obj[key] === "uploadedFile") {
      console.log("obj[key]", obj[key].length);
      if (obj[key].length === 0) {
        return false;
      }
    }
  }
  return true;
}
