import { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useGetDepartmentsQuery } from '../../Redux/Service/Department';
import { useGetRolesQuery } from '../../Redux/Service/RoleApi';
import { Link, useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from '../../Redux/Service/UserApi';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function UserAdd() {
    const [departments, setDepartment] = useState(null);
    const [roles, setRoles] = useState(null);
    const navigate = useNavigate();
    const { data: departmentsData, isLoading: isLoadingForDepartment, isError: isErrorForDepartment, error: errorForDepartment } = useGetDepartmentsQuery();
    const { data: rolesData, isLoading: isLoadingForRole, isError: isErrorForRole, error: errorForRole } = useGetRolesQuery();
    const [ createUser ] = useCreateUserMutation();

    useEffect(() => {
        if (!isLoadingForDepartment && !isErrorForDepartment) {
            setDepartment(departmentsData?.response);
            console.log(departmentsData?.response);
        }
        if (!isLoadingForRole && !isErrorForRole) {
            setRoles(rolesData?.response);
            console.log(rolesData?.response);
        }

    }, [isLoadingForRole, isErrorForRole, departments, isLoadingForDepartment, isErrorForDepartment, roles, departmentsData, rolesData]);

    const initialValues = {
        name: '',
        email: '',
        phoneNo: '',
        roleId: '',
        departmentId: '',
        password:''
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phoneNo: Yup.string().min(10, 'Phone number must be at least 10 characters').required('Phone No is required'),
        roleId: Yup.string().required('Role is required'),
        departmentId: Yup.string().required('Department is required'),
        password: Yup.string().required('Password is required'),
    });

    const handleSubmit = async (values, { resetForm }) => {
        values.roleId = parseInt(values.roleId);
        values.departmentId = parseInt(values.departmentId);
        const createUserData = await createUser(values);
        console.log(createUserData);
        if(createUserData && createUserData?.error){
            toast.error(createUserData?.error?.data?.message);
        }else if(createUserData){
            toast.success(createUserData.data?.message);
            navigate('/user');
        }else{
            resetForm();
            toast.error('Something went wrong, Please try again later!');
        }
    };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Add User</h1>
                        </div>
                        <div className="">
                            <Link to="/user" className="btn btn-dark"> <ArrowBackIcon/> Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Add</h3>
                </div>
                <div className="card-body">
                    {isLoadingForRole || isLoadingForDepartment ? (
                        <div>Loading...</div>
                    ) : errorForRole || errorForDepartment ? (
                        <div>Error: {errorForRole?.message}{errorForDepartment?.message}</div>
                    ) : (
                        <Formik 
                            initialValues={initialValues} 
                            validationSchema={validationSchema} 
                            onSubmit={handleSubmit}>
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <Field type="text" name="name" className="form-control" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="role">Role</label>
                                    <Field as="select" name="roleId" className="form-control">
                                        <option value="">Select Role</option>
                                        {
                                            roles?.map(val=><option key={val.id} value={val.id}>{val.name}</option>)
                                        }
                                    </Field>
                                    <ErrorMessage name="roleId" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="department">Department</label>
                                    <Field as="select" name="departmentId" className="form-control">
                                        <option value="">Select Department</option>
                                        {
                                            departments?.map(val=><option key={val.id} value={val.id}>{val.name}</option>)
                                        }
                                    </Field>
                                    <ErrorMessage name="departmentId" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <Field type="email" name="email" className="form-control" />
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">Phone No</label>
                                    <Field type="text" name="phoneNo" className="form-control" />
                                    <ErrorMessage name="phoneNo" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Field type="password" name="password" className="form-control" />
                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                </div>

                                <button type="submit" className="btn btn-dark">Submit</button>
                            </Form>
                        </Formik>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserAdd;
