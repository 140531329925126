import { useParams, Link } from 'react-router-dom';
import { useGetUserByIdQuery } from '../../Redux/Service/UserApi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function UserView() {
    const { id } = useParams();
    const { data: userData, isLoading: isLoadingForUser, error: errorForUser } = useGetUserByIdQuery(id);
    
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">User Details</h1>
                        </div>
                        <div className="">
                            <Link to="/user" className="btn btn-dark"> <ArrowBackIcon/> Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Details</h3>
                </div>
                <div className="card-body">
                    {isLoadingForUser ? (
                        <div>Loading...</div>
                    ) : errorForUser ? (
                        <div>Error: {errorForUser.message}</div>
                    ) : (
                        <form className="user-details-form">
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input type="text" className="form-control" id="name" value={userData?.response?.name} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input type="email" className="form-control" id="email" value={userData?.response?.email} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNo">Phone No:</label>
                                <input type="text" className="form-control" id="phoneNo" value={userData?.response?.phoneNo} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="roleId">Role:</label>
                                <input type="text" className="form-control" id="roleId" value={userData?.response?.role?.[0]?.name} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="departmentId">Department:</label>
                                <input type="text" className="form-control" id="departmentId" value={userData?.response?.department?.name} readOnly />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserView;
