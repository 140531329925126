import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        user: { emai: '' },
        isAuthorized: false
    },
    reducers: {
        setLogin: (state, action) => {
            state = {...state,token:action?.payload?.token, user:{...action.payload.user},isAuthorized:action.payload.token ? true : false}
            return state;
        },
        logout: (state, action) => {
            state = {}
            if(!action){
                toast.success("Logout successful !");
            }
            return state;
        }
    },
});

export const { setLogin, logout } = authSlice.actions;

export default authSlice.reducer;