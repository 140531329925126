import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const quotationApi = createApi({
    reducerPath: 'quotationApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Quotation'],
    endpoints: (builder) => ({
        createQuotation: builder.mutation({
            query: (req) => {
                const formData = new FormData();
                for (const key in req) {
                    if (key === 'signature' && req[key]) {
                        formData.append('signature', req[key]);
                    } else if (key === 'quotationDetails') {
                        req[key].forEach((detail, index) => {
                            for (const detailKey in detail) {
                                formData.append(`quotationDetails[${index}][${detailKey}]`, detail[detailKey]);
                            }
                        });
                    } else {
                        formData.append(key, req[key]);
                    }
                }

                return {
                    url: 'quotation/create',
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: [{ type: 'Quotation', id: 'LIST' }],
        }),

        getQuotations: builder.query({
            query: ({ limit = 10, page = 0, orderBy = 'DESC', search = '' }) => ({
                url: `quotation?limit=${limit}&page=${page}&orderBy=${orderBy}&search=${search}`,
                method: 'GET',
            }),
            providesTags: [{ type: 'Quotation', id: 'LIST' }],
        }),

        findQuotation: builder.query({
            query: (id) => ({
                url: `quotation/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Quotation', id }],
        }),

        updateQuotation: builder.mutation({
            query: (req) => {
                const formData = new FormData();
                for (const key in req) {
                    if (key === 'signature' && req[key]) {
                        formData.append('signature', req[key]);
                    } else if (key === 'quotationDetails') {
                        req[key].forEach((detail, index) => {
                            for (const detailKey in detail) {
                                formData.append(`quotationDetails[${index}][${detailKey}]`, detail[detailKey]);
                            }
                        });
                    } else {
                        formData.append(key, req[key]);
                    }
                }

                return {
                    url: `quotation/update/${req.id}`,
                    method: 'PUT',
                    body: formData,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Quotation', id }],
        }),

        // updateQuotation: builder.mutation({
        //     query: (req) => ({
        //         url: `quotation/update/${req.id}`,
        //         method: 'PUT',
        //         body: req,
        //     }),
        //     invalidatesTags: (result, error, { id }) => [{ type: 'Quotation', id }],
        // }),

        updateQuotationStatus: builder.mutation({
            query: (id) => ({
                url: `quotation/status/${id}`,
                method: 'PUT'
            })
        }),

        getQuotationStatus: builder.query({
            query: () => ({
                url: "quotation/status",
                method: 'GET',
            })
        }),
    }),
});

export const {
    useCreateQuotationMutation,
    useUpdateQuotationMutation,
    useUpdateQuotationStatusMutation,
    useGetQuotationsQuery,
    useFindQuotationQuery,
    useGetQuotationStatusQuery
} = quotationApi;