import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArticleIcon from '@mui/icons-material/Article';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

function SideNav() {
    return (
        <>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <Link to="/" className="brand-link">
                    {/* <img src="public/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                    <span className="brand-text font-weight-light">PRE SALES</span>
                </Link>
                <div className="sidebar">

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="/user" className="nav-link">
                                    <FaUsers className="icon-size" />
                                    <p> Users</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/estimate" className="nav-link">
                                    <DocumentScannerIcon className="icon-size" />
                                    <p> Estimate</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/quotation" className="nav-link">
                                    <PostAddIcon className="icon-size" />
                                    <p> Quotation</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/contract" className="nav-link">
                                    <ArticleIcon className="icon-size" />
                                    <p> Contract</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/leads" className="nav-link">
                                    <LeaderboardIcon className="icon-size" />
                                    <p> leads</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}

export default SideNav
