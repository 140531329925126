import Header from "./Header"
import SideNav from "./SideNav"
import Footer from "./Footer"
import { Outlet, Route, Routes } from 'react-router-dom'
import propsType from 'prop-types'; 

function MainLayout(props) {
    const { childRoutes } = props;
    return (
        <div className="main-layout">
            <Header />
            <SideNav />
            <Routes>
                {childRoutes && childRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={<route.component />} />
                ))}
            </Routes>
            <Outlet />
            {/* <Navigate to='/dashboard' /> */}
            <Footer />
           
        </div>
    )
}

MainLayout.propTypes = {
    childRoutes : propsType.array.isRequired
}

export default MainLayout
