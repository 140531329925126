import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useLoginMutation } from '../../Redux/Service/AuthApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


function Login() {

    const [login ] = useLoginMutation();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await login({ email: values.email, password: values.password });
            navigate('/dashboard');
        } catch (error) {
            toast.error('Login failed. Please try again.');
            console.error('Login failed', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-dark p-4">
                    <div className="card-header text-center border-0">
                        <span className="h1"><b>Pre </b>Sales</span>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg"></p>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, touched, errors, handleBlur }) => (
                                <Form autoComplete="off">
                                    <div className="input-group mb-3">
                                        <Field
                                            type="email"
                                            className={`form-control${touched.email && errors.email ? ' is-invalid' : ''}`}
                                            name="email"
                                            placeholder="Email"
                                            onBlur={handleBlur}
                                            autoComplete="off"
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>

                                    <div className="input-group mb-3">
                                        <Field
                                            type="password"
                                            className={`form-control${touched.password && errors.password ? ' is-invalid' : ''}`}
                                            name="password"
                                            placeholder="Password"
                                            onBlur={handleBlur}
                                            autoComplete="off"
                                        />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>

                                    <div className="row">
                                        <div className="col-8"></div>
                                        <div className="col-4">
                                            <button
                                                type="submit"
                                                className="btn btn-dark btn-block"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Logging in...' : 'Sign In'}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
