import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const providerApi = createApi({
    reducerPath: 'providerApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getProvider: builder.query({
            query: () => ({
                url: `/service`,
                method: 'GET',
            }),
            async onQueryStarted(queryArg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    return err;
                }
            },
        }),
    }),
});

export const { useGetProviderQuery } = providerApi;