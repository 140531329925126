import { Button, Grid } from '@mui/material';
import { useEffect, useState  } from 'react';
import DataTable from 'react-data-table-component';
import { Dropmenu, Search } from '../../Components';
import { useGetUsersQuery, useUpdateStatusMutation } from '../../Redux/Service/UserApi';
import { Link, useNavigate,  } from "react-router-dom";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { toast } from 'react-toastify';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

function UserList() {
    const navigate = useNavigate();
    const [userData, setUserData ] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const { data: users, refetch, isLoading, isError, error } = useGetUsersQuery({ limit:10, page: 0, orderBy:'DESC', search:searchQuery});
    const [ updateStatus ] = useUpdateStatusMutation();

    const customStyles = {
        rows: {
            style: {
                margin:'0px',
                padding: 0
            },
        },
        headCells: {
            style: {
                margin:'0px',
                padding: '10px 15px',
                fontWeight: 600,
                fontSize: 15,
                background: '#f3f3f3',
            },
        },
        cells: {
            style: {
                margin:'0px',
                padding: '10px 15px',
                fontSize: 15,
            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    useEffect(() => {
        refetch();
    }, [refetch, searchQuery]);

    useEffect(() => {
        if (!isLoading && !isError) {
            setUserData(users?.response?.data);
        }
    }, [isLoading, isError, users]);

    const handleEdit = (id) => {
        navigate(`/user-edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/user/${id}`);
    }

    const handleSearch = (search) => {
        setSearchQuery(search);
    }

    const handleStatus = async (id) => {
        try {
            const updateStatusData = await updateStatus(id);
            if (updateStatusData) {
                const updatedUserData = [...userData];
                const index = updatedUserData.findIndex(user => user.id === id);
    
                if (index !== -1) {
                    updatedUserData[index] = {
                        ...updatedUserData[index],
                        status: !updatedUserData[index].status
                    };
                    toast.success(updateStatusData.data?.message);
                    setUserData(updatedUserData);
                } else {
                    toast.error('User Not Found!');
                }
            } else {
                toast.error('Failed to update status!');
            }
        } catch (error) {
            toast.error('An error occurred while updating status!');
        }
    };

    const columns = [
        {
            name: 'SL No',
            cell: (row, index) => index + 1,
            button: false,
        },    
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Department',
            selector: row => row.department,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone No',
            selector: row => row.phoneNo,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status ?
             <div>
                <Button size="small" variant="outlined"  color="success" onClick={() => {handleStatus(row.id)}} ><ToggleOnIcon/> {row.status} </Button>
             </div>: 
             <div>
                 <Button size="small" variant="outlined" color="error" onClick={() => {handleStatus(row.id)}} ><ToggleOffIcon/> {row.status} </Button>
            </div>,
        },
        {
            name: 'Action',
            cell: (row) => (
                <div>
                    <Dropmenu handleEdit={() => handleEdit(row.id)} handleView={() => handleView(row.id)}/>
                </div>
            ),
            button: false,
        }
    ];

    return ( 
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="">
                            <h1 className="m-0 badge badge-dark">Users</h1>
                        </div>
                        <div className="">
                            <Link to="/user-add" className="btn btn-dark"><PersonAddAlt1Icon/> Add User</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-3 card-outline card-dark">
                {isLoading ? (
                <div>Loading...</div>
                ) : isError ? (
                    <div>Error: {error?.message}</div>
                ) : (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}></Grid>
                            <Grid item md={6} xs={12} display='flex' justifyContent='flex-end'>
                                <Search onSearch={(query) => handleSearch(query) } />
                            </Grid>
                        </Grid>

                        <DataTable
                            columns={columns}
                            data={
                                userData?.map(({ id, name, email, phoneNo, role, department, status }) => ({
                                    id,
                                    name,
                                    email,
                                    phoneNo,
                                    role: role && role[0]?.name,
                                    department: department?.name,
                                    status 
                                })) 
                            }
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            customStyles={customStyles}
                        /> 
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserList;
