/* eslint-disable no-unused-vars */
/* eslint-disable-next-line */
/* eslint-disable-next-line */

import React from 'react';
import { FieldArray, ErrorMessage } from 'formik';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export default function StepSeven({ values, setFieldValue, touched, errors }) {

    const handleFileUpload = (event, index) => {
        const file = event.currentTarget.files[0];
        const updatedFiles = [...values.uploadedFile];
        updatedFiles[index] = file;
        setFieldValue('uploadedFile', updatedFiles);
    };

    const handleSignatureUpload = (event) => {
        const file = event.currentTarget.files[0];
        setFieldValue('signature', file);
    };

    return (
        <>
            <div className="card card-dark p-3 m-3">
                <div className="card-header">
                    <h3 className="card-title">Upload Documents</h3>
                </div>
                <Box className="card-body" sx={{ padding: "2rem 0" }}>
                    <Grid container spacing={2}>
                        {/* Document Uploads */}
                        <FieldArray
                            name="uploadedFile"
                            render={arrayHelpers => {
                                // Create a reversed copy of the uploaded files array
                                const reversedFiles = [...values.uploadedFile].reverse();

                                return (
                                    <>
                                        {reversedFiles && reversedFiles.length > 0 ? (
                                            reversedFiles.map((file, revIndex) => {
                                                // Calculate the original index since reversedFiles is reversed
                                                const originalIndex = values.uploadedFile.length - 1 - revIndex;

                                                return (
                                                    <Grid container spacing={2} key={originalIndex} alignItems="center">
                                                        <Grid item md={10} xs={10}>
                                                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2, marginBottom: 2 }}>
                                                                <Typography variant="h6" gutterBottom align="left">
                                                                    Upload Document {originalIndex + 1}
                                                                </Typography>
                                                                <input
                                                                    type="file"
                                                                    onChange={(event) => handleFileUpload(event, originalIndex)}
                                                                    accept=".pdf,.doc,.docx,.png,.jpg"
                                                                />
                                                                {file && (
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {file.name}
                                                                    </Typography>
                                                                )}
                                                                <ErrorMessage name={`uploadedFile[${originalIndex}]`} component="div" style={{ color: 'red', marginTop: '8px' }} />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={2} xs={2} display="flex" justifyContent="center" alignItems="center">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => arrayHelpers.remove(originalIndex)}
                                                                disabled={values.uploadedFile.length === 1} // Disable delete if only one file input remains
                                                                sx={{ padding: 0 }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Typography variant="body2" color="textSecondary" sx={{ paddingLeft: 2 }}>
                                                    No files uploaded yet.
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid container spacing={2} justifyContent="flex-end">
                                            <IconButton
                                                aria-label="add"
                                                onClick={() => arrayHelpers.push(null)} // Adds a new file upload field
                                                sx={{ padding: 0 }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </>
                                );
                            }}
                        />

                        {/* Signature Upload */}
                        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                            <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                <Typography variant="h6" gutterBottom align="left">
                                    Upload Signature
                                </Typography>
                                <input
                                    type="file"
                                    onChange={handleSignatureUpload}
                                    accept=".png,.jpg"
                                />
                                {values.signature && (
                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                        {values.signature.name}
                                    </Typography>
                                )}
                                <ErrorMessage name="signature" component="div" style={{ color: 'red', marginTop: '8px' }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}

