import { createApi } from '@reduxjs/toolkit/query/react';
import {setLogin} from '../Reducers/Auth/AuthSilice'
import { toast } from 'react-toastify';
import { FetchBaseQuery } from './FetchBaseQuery';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: FetchBaseQuery,
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (req) => ({
                url: `auth/login`,
                method: 'POST',
                body: req,
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setLogin(data.response))
                    toast.success("Login successful !");
                } catch (err) {
                    toast.error(err.error.data.message);
                    return err;
                }
            }
        }),
    }),
});

export const { useLoginMutation } = authApi;