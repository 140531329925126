import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const leadApi = createApi({
    reducerPath: 'leadApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getLeads: builder.query({
            query: () => ({
                url: `/lead`,
                method: 'GET',
            }),
            async onQueryStarted(queryArg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    return err;
                }
            },
        }),

        getPaginatedLeads: builder.query({
            query: ({limit, page, orderBy, search}) => ({
                url: `/lead/pagination-search?limit=${limit}&page=${page}&orderBy=${orderBy}&search=${search}`,
                method: 'GET',
            }),
            async onQueryStarted(queryArg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    return err;
                }
            },
        }),
    }),
});

export const { useGetLeadsQuery, useGetPaginatedLeadsQuery } = leadApi;