import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './FetchBaseQuery';

export const contractApi = createApi({
    reducerPath: 'contractApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Contract'],
    endpoints: (builder) => ({
        getContractList: builder.query({
            query: ({ limit = 10, page = 1, orderBy = 'DESC', search = '' }) => ({
                url: `/contract/list?limit=${limit}&page=${page}&orderBy=${orderBy}&search=${search}`,
                method: 'GET',
            }),

            providesTags: [{ type: 'Contract', id: 'LIST' }],
            
        }),

    }),
});

export const { 
    useGetContractListQuery
} = contractApi;