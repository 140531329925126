import { createApi } from '@reduxjs/toolkit/query/react';
import { FetchBaseQuery } from './FetchBaseQuery';

export const departmentApi = createApi({
    reducerPath: 'departmentApi',
    baseQuery: FetchBaseQuery,
    endpoints: (builder) => ({
        getDepartments: builder.query({
            query: () => ({
                url: `/department`,
                method: 'GET',
            }),
            async onQueryStarted(queryArg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    return err;
                }
            },
        }),
    }),
});

export const { useGetDepartmentsQuery } = departmentApi;