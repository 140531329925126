/* eslint-disable no-unused-vars */
import React from 'react';
import { Field, ErrorMessage, FieldArray } from 'formik';
import { Grid, TextField, Box, Typography } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';

function StepSix({ isSubmitting, values, setFieldValue, touched, errors, initialValues }) {


    return (
        <>
        <div className="card card-dark p-3 m-3">
            <div className="card-header">
                <h3 className="card-title">Terms & Conditions</h3>
            </div>
            <Box className="card-body" sx={{ padding: "15px 0" }}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                            <Grid container spacing={2}>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            General Terms & Conditions
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="general_terms_and_conditions">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('general_terms_and_conditions', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="general_terms_and_conditions" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Project Termination Terms
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="project_termination_terms">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('project_termination_terms', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="project_termination_terms" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Payment Terms & Conditions
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="payment_terms">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('payment_terms', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="payment_terms" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Application  Ownership
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="application_ownership">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('application_ownership', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="application_ownership" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Liability
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="liability">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('liability', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="liability" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Box sx={{ border: '1px solid grey', borderRadius: '8px', p: 2 }}>
                                        <Typography variant="h6" gutterBottom align="left">
                                            Legal Jurisdiction
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field name="legal_jurisdiction">
                                                    {({ field }) => (
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={field.value}
                                                            onChange={(event, editor) => setFieldValue('legal_jurisdiction', editor.getData())}
                                                        />
                                                    )}
                                                </Field>
                                                <ErrorMessage name="legal_jurisdiction" component="div" style={{ color: 'red' }} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    </>
    )
}

export default StepSix;
