import { RiLockPasswordFill } from "react-icons/ri";
import { usePasswordChangeMutation } from '../Redux/Service/UserApi';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function ChangePassword() {
  const [passwordChange ] = usePasswordChangeMutation();
  const navigate = useNavigate();
  const initialValues = { oldPassword: '', newPassword: '' };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .min(6, 'New Password must be at least 6 characters long')
      .notOneOf([Yup.ref('oldPassword')], 'New Password must be different from Old Password')
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //   'New Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    // ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const passwordUpdate = await passwordChange(values);
    if (!passwordUpdate?.error?.data?.statusCode) {
      navigate('/dashboard');
    }
    resetForm();
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 badge badge-dark">Password Change</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-dark m-3">
        <div className="card-header">
          <h3 className="card-title"><RiLockPasswordFill className='icon-size' /> Change Password</h3>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="oldPassword">Old Password</label>
                <Field
                  type="password"
                  name="oldPassword"
                  className="form-control"
                  id="oldPassword"
                />
                <ErrorMessage name="oldPassword" component="div" className="text-danger" />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <Field
                  type="password"
                  name="newPassword"
                  className="form-control"
                  id="newPassword"
                />
                <ErrorMessage name="newPassword" component="div" className="text-danger" />
              </div>
            </div>
            <div className="card-footer">
              <button type="submit" className="btn btn-dark">Submit</button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
