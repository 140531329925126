import { createApi } from '@reduxjs/toolkit/query/react';
import { FetchBaseQuery } from './FetchBaseQuery';

export const roleApi = createApi({
    reducerPath: 'roleApi',
    baseQuery: FetchBaseQuery,
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: () => ({
                url: `/role`,
                method: 'GET',
            }),
            async onQueryStarted(queryArg, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    return err;
                }
            },
        }),
    }),
});

export const { useGetRolesQuery } = roleApi;