/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
// import CustomDatePicker from '../../Components/MUI/CustomDatePicker';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

function StepFive({
  isSubmitting,
  values,
  setFieldValue,
  touched,
  errors,
  initialValues,
}) {
  // Redux States
  const bankList = useSelector(
    (state) => state.contractState.bankDetails
  );

  const [BankOptions, setBankOptions] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);


  useEffect(() => {
    // Fetching technology options (you could fetch from an API in real implementation)
    setBankOptions(bankList);

    setSelectedBank(
      values.Bank_Details?.map((id) => {
        return bankList.find((item) => item.id === id);
      })
    )
  }, [selectedBank, bankList, values.Bank_Details]);

  const handleAddBank = (BankId) => {
    const selectedBank = bankList.find(item => item.id === BankId);
    setSelectedBank(prev => [...prev, selectedBank]);
    setFieldValue('Bank_Details', [...values.Bank_Details, selectedBank.id]);
  };


  const handleRemoveBank = (bankId) => {
    const updatedBank = selectedBank.filter(
      (item) => item.id !== bankId
    );
    setSelectedBank(updatedBank);
    const updatedBankIds = updatedBank?.map((item) => item.id);
    setFieldValue("Bank_Details", updatedBankIds);
  };


  // Redux State for Contract Prefilled Data
  const contractPrefilledData = useSelector((state) => state.contractState?.prefilledData?.response);


  // Set Pre Filled Data For Step Six
  React.useEffect(() => {
    if (contractPrefilledData) {
      // Format All the Data
      const gen_terms = `
  <ul>
    ${contractPrefilledData[3]?.data?.general_condition
          ?.map((term, index, array) => {
            // Start sub-points at index 4 and go until the second-to-last item
            if (index === 4) {
              return `
            <li>${term}
              <ul>
                ${array.slice(5, array.length - 2)
                  .map((subTerm) => `<li>${subTerm}</li>`)
                  .join("")}
              </ul>
            </li>
          `;
            }
            // Render the last two items after the sub-points
            else if (index >= array.length - 2) {
              return `<li>${term}</li>`;
            }
            // Render initial items as regular list items
            else if (index < 4) {
              return `<li>${term}</li>`;
            }
            // Do nothing for items already added as sub-points
            else {
              return '';
            }
          })
          .join("")}
  </ul>
`;

      const project_termination_terms = `
  <ul>
    ${contractPrefilledData[3]?.data?.project_termination_term
          ?.map((term) => `<li>${term}</li>`)
          .join("")}
  </ul>
`;
      const payment_terms = `
  <ul>
    ${contractPrefilledData[3]?.data?.payment_term_condition
          ?.map((term) => `<li>${term}</li>`)
          .join("")}
  </ul>
`;;

      // Set All the Data
      setFieldValue('general_terms_and_conditions', gen_terms);
      setFieldValue('project_termination_terms', project_termination_terms);
      setFieldValue('payment_terms', payment_terms);
      setFieldValue('application_ownership', contractPrefilledData[4]?.data?.app_owner);
      setFieldValue('liability', contractPrefilledData[5]?.data?.liability);
      setFieldValue('legal_jurisdiction', contractPrefilledData[6]?.data?.legal_jury);
    }
  }, [contractPrefilledData, setFieldValue])

  return (
    <>
      <div className="card card-dark p-3 m-3">
        <div className="card-header">
          <h3 className="card-title">Bank Details</h3>
        </div>
        <Box className="card-body" sx={{ padding: "15px 0" }}>
          <Grid item md={12} xs={12}>
            <Box sx={{ border: "1px solid grey", borderRadius: "8px", p: 2 }}>
              <Typography variant="h6" gutterBottom align="left">
                Select Bank Account
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    label="Select Bank Account"
                    onChange={(e) => handleAddBank(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Bank Account
                    </MenuItem>
                    {BankOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.bank_name} - ********{option.account_no.substring(option.account_no.length - 4)} : {option.ifsc_Code}
                      </MenuItem>
                    ))}
                  </Select>

                  <ErrorMessage
                    name="selected_bank_account"
                    component="div"
                    style={{ color: "red" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Selected Bank Account Details:
                  </Typography>
                  {selectedBank.length > 0 && <Box>
                    {selectedBank?.map((Bank, index) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Typography variant="body2">{Bank.bank_name} - ********{Bank.account_no?.substring(Bank.account_no.length - 4)} : {Bank.ifsc_Code}</Typography>
                        <Button
                          onClick={() => handleRemoveBank(Bank.id)}
                          startIcon={<DeleteIcon />}
                          size="small"
                          sx={{ ml: 1 }}
                        >
                          Remove
                        </Button>
                      </Box>
                    ))}
                  </Box>}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default StepFive;
